<template>
  <div class="warp">
    <img src="../assets/images/cuowu.png" width="70" height="70">
    <h3 v-if="this.$route.query.msg">{{this.$route.query.msg}}</h3>
    <h3 v-else>您没有权限访问该页面</h3>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  created() {
  }
}

</script>
<style lang="scss" scoped>
.warp {
  text-align: center;
  padding: 100px 30px 0;
  box-sizing: border-box;
  img {
    margin: 50px 0;
  }
}
</style>
